

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-family: "Exo", sans-serif;
    font-weight: 700;
    color: #222;
}
h1,
.h1 {
    font-size: 2.5rem;
}

h2,
.h2 {
    font-size: 44px;
}

h3,
.h3 {
    font-size: 1.5rem;
}

h4,
.h4 {
    font-size: 1.3rem;
    line-height: 30px;
}

h5,
.h5 {
    font-size: 1.25rem;
}

h6,
.h6 {
    font-size: 1rem;
}

p {
    line-height: 30px;
}

.navbar-toggle .icon-bar {
    background: #223a66;
}/* Estilos para pantallas grandes */
@media (min-width: 1920px) {
    .container {
        padding: 0 50px;
    }
}

/* Estilos para pantallas medianas (por ejemplo, tablets) */
@media (max-width: 991px) {
    .container {
        padding: 0 30px;
    }

    .header-top-bar {
        font-size: 12px;
    }
}

/* Estilos para pantallas pequeñas (móviles) */
@media (max-width: 576px) {
    .header-top-bar {
        font-size: 10px;
        padding: 5px 0;
    }

    .header-top-bar .container {
        flex-direction: column;
        align-items: center;
    }

    .header-top-bar .top-bar-info,
    .header-top-bar .weather {
        margin-bottom: 10px;
    }
}

/* Estilos específicos para la sección de historia */
.historia-section {
  margin: 20px 0;
}

.historia-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #2a9d8f; /* Color del título */
}

.historia-content {
  display: flex;
  flex-direction: column;
}

.historia-item {
  margin-bottom: 15px;
}

.historia-date {
  font-size: 18px;
  font-weight: bold;
  color: #264653; /* Color de las fechas */
}

.historia-description {
  font-size: 16px;
  margin-top: 5px;
}
/* Estilos específicos para la sección de historia */
.historia-section {
  margin: 20px 0;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 10px;
}

.historia-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #2a9d8f;
  text-align: center;
}

.historia-resumen p {
  font-size: 16px;
  margin-bottom: 30px;
  color: #333;
  text-align: justify;
}

.historia-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.historia-item {
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.historia-date {
  font-size: 18px;
  font-weight: bold;
  color: #264653;
}

.historia-description {
  font-size: 16px;
  margin-top: 5px;
}
/* Estilos generales */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
}

.container {
  max-width: 100%;
  margin: 0 auto;
  padding: 15px;
}

.section {
  padding: 50px 0;
}

h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

/* Grid de noticias */
.page-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columnas en pantallas grandes */
  gap: 20px;
}

.card-noticia {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  height: 100%;
  text-align: center;
}

.card-noticia:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
}

.card-noticia img {
  width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 200px;
}

.card-noticia .content {
  padding: 15px;
}

.card-noticia .title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

/* Barra lateral */
.sidebar-wrap {
  background-color: #f9f9f9;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 30px;
}

.sidebar-widget h5 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.sidebar-widget ul {
  list-style-type: none;
}

.sidebar-widget ul li {
  margin-bottom: 10px;
}

.sidebar-widget ul li a {
  text-decoration: none;
  color: #007bff;
  font-size: 1rem;
}

.sidebar-widget ul li a:hover {
  text-decoration: underline;
}

/* Estilos responsivos */
@media (max-width: 992px) {
  .row-data-noticia {
    display: flex;
    flex-direction: column-reverse; /* Barra lateral debajo de las noticias */
  }

  .col-lg-8, .col-md-8, .col-lg-4, .col-md-4 {
    width: 100%;
    margin-bottom: 30px;
  }

  .page-content {
    grid-template-columns: repeat(1, 1fr); /* 1 columna en pantallas medianas */
  }
}

@media (max-width: 600px) {
  .card-noticia img {
    max-height: 150px; /* Reducción del tamaño de la imagen en pantallas pequeñas */
  }

  .card-noticia .title {
    font-size: 1rem; /* Ajuste del tamaño del título en pantallas pequeñas */
  }

  .page-content {
    grid-template-columns: repeat(1, 1fr); /* 1 columna en pantallas pequeñas */
  }
}.section.clients {
  padding: 60px 0;
}

.section-title {
  margin-bottom: 40px;
}

.section-title h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.section-title p {
  font-size: 1rem;
  color: #555;
}

.clients-slider .slick-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.client-thumb {
  display: flex;
  justify-content: center; /* Centrar horizontalmente */
  align-items: center;     /* Centrar verticalmente */
  padding: 10px;           /* Espacio alrededor de las imágenes */
}

.client-thumb img {
  width: 150px;    /* Ajustar el ancho de todas las imágenes */
  height: 150px;    /* Mantener la proporción de la imagen */
  object-fit: contain; /* Asegura que la imagen se ajuste dentro de su contenedor */
}
.ceo-page {
  font-family: Arial, sans-serif;
}

.ceo-introduction {
  padding: 60px 0;
}

.ceo-introduction img {
  max-width: 100%;
  border-radius: 50%;
}

.ceo-name {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.ceo-description {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
}

.ceo-quote {
  font-style: italic;
  margin-bottom: 2rem;
}

.ceo-buttons {
  margin-top: 2rem;
}

.btn {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  font-size: 16px;
}

.btn-social {
  background-color: #0077b5; /* Color de fondo para LinkedIn */
}

.btn-social:nth-child(2) {
  background-color: #4dcf6b; /* Color de fondo para Facebook */
}

.btn-social:nth-child(3) {
  background-color: #5b9b73; /* Color de fondo para WhatsApp */
}

.btn-portfolio {
  background-color: #e12454; /* Color de fondo para el botón de Portafolio */
}

.btn i {
  margin-right: 8px;
}

.ceo-milestones {
  padding: 60px 0;
}

.milestones-list {
  list-style: none;
  padding: 0;
}

.milestones-list li {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
}
.contenedor-redes-sociales {
    margin: 20px 0;
    padding: 20px;
    text-align: center;
}

.contenedor-redes-sociales a {
    position: relative;
    display: inline-block;
    height: 36px;
    width: 160px;
    line-height: 35px;
    padding: 0;
    color: #fff;
    border-radius: 50px;
    background: #fff;
    margin: 5px;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24);
}

.contenedor-redes-sociales a:hover span.circulo {
    left: 100%;
    margin-left: -35px;
    background: #fff;
}

.contenedor-redes-sociales a:hover span.titulo {
    opacity: 0;
}

.contenedor-redes-sociales a:hover span.titulo-hover {
    opacity: 1;
    color: #fff;
}

.contenedor-redes-sociales a span.titulo-hover {
    opacity: 0;
}

.contenedor-redes-sociales a span.circulo {
    display: block;
    color: #fff;
    position: absolute;
    float: left;
    margin: 3px;
    line-height: 30px;
    height: 30px;
    width: 30px;
    top: 0;
    left: 0;
    transition: all 0.5s;
    border-radius: 50%;
}

.contenedor-redes-sociales a span.circulo i {
    width: 100%;
    text-align: center;
    font-size: 16px;
    line-height: 30px;
}

.contenedor-redes-sociales a span.titulo, .contenedor-redes-sociales a span.titulo-hover {
    position: absolute;
    text-align: center;
    margin: 0 auto;
    font-size: 16px;
    font-weight: 400;
    transition: .5s;
}

.contenedor-redes-sociales a span.titulo {
    right: 15px
}

.contenedor-redes-sociales a span.titulo-hover {
    left: 15px
}

/*----------Colores de los botones----------*/
.contenedor-redes-sociales .facebook {
    border: 2px solid #3b5998;
}

.contenedor-redes-sociales .facebook:hover, .contenedor-redes-sociales .facebook span.circulo {
    background: #3b5998;
}

.contenedor-redes-sociales .facebook:hover span.circulo, .contenedor-redes-sociales .facebook span.titulo {
    color: #3b5998;
}

.contenedor-redes-sociales .twitter {
    border: 2px solid #45867b;
}

.contenedor-redes-sociales .twitter:hover, .contenedor-redes-sociales .twitter span.circulo {
    background: #45867b;
}

.contenedor-redes-sociales .twitter:hover span.circulo, .contenedor-redes-sociales .twitter span.titulo {
    color: #45867b;
}

.contenedor-redes-sociales .gplus {
    border: 2px solid #10792e;
}

.contenedor-redes-sociales .gplus:hover, .contenedor-redes-sociales .gplus span.circulo {
    background: #43994d;
}

.contenedor-redes-sociales .gplus:hover span.circulo, .contenedor-redes-sociales .gplus span.titulo {
    color: #548b67;
}

/*--------------Mediaqueries--------------*/
@media screen and (max-width: 480px) {
    .contenedor-redes-sociales a {
        width: 100%;
        margin: 0;
        margin-bottom: 10px;
    }
}
.features {
    width: 100%; /* Asegura que el contenedor ocupe el 100% del ancho del viewport */
    display: flex; /* Usa flexbox para distribuir los elementos en una fila */
    flex-wrap: wrap; /* Permite que los elementos se envuelvan si es necesario */
    justify-content: center; /* Centra los elementos horizontalmente */
    align-items: center; /* Centra los elementos verticalmente si es necesario */
    margin-top: -90px; /* Mantén este margen si es necesario para tu diseño */
}

.feature-item {
    flex: 1 1 30%; /* Ajusta el tamaño base y permite que los ítems crezcan y se encojan */
    margin: 10px; /* Ajusta el margen entre los elementos */
    padding: 40px 30px;
    background-color: #ffffff8a;
    border-radius: 15px;
    box-shadow: 0px 0px 30px 0px rgba(0, 42, 106, 0.1);
    text-align: center; /* Centra el texto dentro de cada ítem */
}

.feature-item .feature-icon i {
    font-size: 50px;
    color: #223a66;
}

.feature-item h4 {
    color: #223a66;
}

.feature-item p {
    font-size: 14px;
}

.feature-section.border-top {
    border-top: 1px solid rgba(0, 0, 0, 0.05) !important;
}

.emergencia {
    text-align: left;
}

.footer {
    padding-bottom: 10px;
}

.footer .copyright a {
    font-weight: 600;
}
.widget .footer-menu a {
    color: #6F8BA4;
}

.widget .footer-menu a:hover {
    color: #e12454;
}
.footer-contact-block span {
    font-weight: 400;
    color: #6F8BA4;
}

.footer-contact-block i {
    font-size: 20px;
}

.footer-btm {
    border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.footer-socials li a {
    width: 45px;
    height: 45px;
    background: #6F8BA4;
    color: #fff;
    display: inline-block;
    text-align: center;
    border-radius: 100%;
    padding-top: 12px;
}
.footer-btm {
    border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.widget h4 {
    color: #223a66;
}
.mt-2{
  color: #0000
}
.subscribe{
  position: relative;
  .form-control{
    border-radius:50px;
    height:60px;
    padding-left: 25px;
    border-color:#eee;
  }
  .btn{
    position: absolute;
    right:6px;
    top:6px;
  }
}
.btn-main-2 {
    background: #e12454;
    color: #fff;
    border-color: #e12454;
}
.btn-round-full {
    border-radius: 50px;
}
.banner-content {
    position: relative;
    z-index: 1;
    padding: 100px 0;
    width: 100%;        /* Ocupará todo el ancho disponible */
    height: 100%;       /* Ocupará todo el alto disponible */
    display: flex;      /* Flexbox para alinear contenido */
    justify-content: center;  /* Centrar contenido horizontalmente */
    align-items: center;      /* Centrar contenido verticalmente */
    box-sizing: border-box;   /* Incluir padding en el tamaño total */
}

/* Ajustes para pantallas pequeñas */
@media (max-width: 767px) {
    .banner-content {
        padding: 50px 20px;  /* Reducir padding en pantallas pequeñas */
    }
}

/* Ajustes para pantallas medianas */
@media (min-width: 768px) and (max-width: 991px) {
    .banner-content {
        padding: 80px 40px;
    }
}
.backtop {
    position: fixed;
    background: #e12454;
    z-index: 9999;
    display: inline-block;
    right: 55px;
    width: 60px;
    height: 60px;
    bottom: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    border-radius: 50px;
}
.btn {
    display: inline-block;
    font-size: 14px;
    font-size: 0.8125rem;
    font-weight: 700;
    letter-spacing: .5px;
    padding: .75rem 2rem;
    font-family: "Exo", sans-serif;
    text-transform: uppercase;
    border-radius: 5px;
    border: 2px solid transparent;
    transition: all .35s ease;
}
.btn-main-2 {
    background: #e12454;
    color: #fff;
    border-color: #e12454;
}
.backtop i {
    color: #fff;
    font-size: 20px;
}
#btn-round-full-k {
  margin-top: -0.4%;
  border-radius:50px;
}
.reveal {
    transition: all .3s;
    cursor: pointer;
    opacity: 1;
}
#navbar {
    background-image: url('https://rath.mac-softins.com/imgpublic/oficialpage/header.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    top: 0;
    left: 0;
    right: 0;
    transition: background-color 0.3s ease;
    z-index: 1000;
}



.navbar-toggle .icon-bar {
    background: #223a66;
}

#navbarmain {
    padding: 20px 0;
}

#navbarmain .nav-link {
    font-weight: 600;
    padding: 10px 15px;
    color: #222;
    font-family: "Exo", sans-serif;
    text-transform: capitalize;
    font-size: 16px;
    transition: all .25s ease;
}

.navbar-brand {
    margin-top: 10px;
}

.navbar-toggle .icon-bar {
    background: #223a66;
}

.dropdown-toggle::after {
    display: none;
}
.dropdown .dropdown-menu {
    position: absolute;
    display: block;
    background: #fff;
    max-width: 100%; /* Evita que se expanda más allá del contenedor */
    top: 130%;
    left: 0;
    right: 0;
    opacity: 0;
    padding: 10px 15px; /* Espaciado interno */
    visibility: hidden;
    transition: all 0.3s ease-out;
    border: 0;
    border-top: 5px solid #e12454;
    border-radius: 0;
    box-sizing: border-box; /* Asegura que el padding no cause desbordamiento */
    font-size: 16px; /* Ajusta el tamaño de la fuente si es necesario */
    word-wrap: break-word; /* Asegura que las palabras largas no desborden el contenedor */
    overflow: hidden; /* Evita el desbordamiento vertical del contenido */
    white-space: normal; /* Permite que el texto se ajuste en múltiples líneas si es necesario */
    text-align: left; /* Alinea el texto dentro del menú */
}

/* Mostrar el menú cuando se pase el ratón */
.dropdown:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
    top: 115%;
}
.img-fluid-h {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
}
/* Estilos para los enlaces dentro del menú */
.dropdown-menu a {
    display: block;
    padding: 10px 15px;
    color: #222;
    text-decoration: none;
    font-weight: 600;
    transition: background-color 0.3s ease;
    white-space: nowrap; /* Mantiene los enlaces en una sola línea */
    overflow: hidden;
    text-overflow: ellipsis; /* Recorta el texto si es demasiado largo */
    box-sizing: border-box; /* Asegura que el padding no cause desbordamiento */
}

/* Cambia el fondo cuando se pasa el cursor por encima del enlace */
.dropdown-menu a:hover {
    background-color: #f1f1f1;
}

/* Ajustes para pantallas más pequeñas */
@media (max-width: 768px) {
    .dropdown .dropdown-menu {
        min-width: 100%;
        left: 0;
        right: 0;
        top: 100%;
    }
}
/* MisionVision.css */


.more-details{
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background: none; /* Elimina cualquier fondo existente */
  position: relative;
}
.more-details::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://rath.mac-softins.com/assets/images/3.svg'); /* Ruta a tu archivo SVG */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  opacity: 0.1; /* Ajusta la opacidad aquí */
  z-index: -1; /* Asegura que la imagen de fondo no tape el contenido */
}
.item {
  margin-bottom: 10px;
}

.item .img-fluid {
  max-width: 100%;
}

.cont {
  margin-left: 10px;
}
.h5mv{
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
  text-align: center;
  color: #0f445f;
}
.h3mv {
  font-size: 14px;
  color: #0f445f;
  margin-bottom: 10px;
}

.pmv{
  font-size: 12px;
  color: #0f445f;
}
.welcome-service {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background: none; /* Elimina cualquier fondo existente */
  position: relative;
}

.welcome-service::before {
  content: "";
  position: fixed; /* Fijado para que la imagen de fondo no se mueva al hacer scroll */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://rath.mac-softins.com/imgpublic/oficialpage/service-bg.jpg'); /* Ruta a tu imagen */
  background-size: cover;
  background-position: center center;

  z-index: -1;
}

.item-circuit {
  background-color: #fff; /* Fondo blanco para el contenedor */
  border-radius: 10px; /* Bordes redondeados */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Sombra para darle profundidad */
  padding: 20px; /* Espacio interno */
  margin: 15px; /* Espacio externo para separar los elementos */
  transition: transform 0.3s ease-in-out; /* Transición suave para el hover */
  cursor: pointer; /* Cambia el cursor al pasar sobre el elemento */
}

.item-circuit:hover {
  transform: scale(1.05); /* Efecto de agrandamiento al hacer hover */
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2); /* Sombra más pronunciada en hover */
}

.item-circuit img {
  max-width: 100%; /* Asegura que la imagen no se salga del contenedor */
  height: auto; /* Mantiene la proporción de la imagen */
  margin-bottom: 15px; /* Espacio entre la imagen y el texto */
}

.item-circuit h3 {
  font-size: 1.5rem; /* Tamaño del título */
  color: #333; /* Color del texto */
  margin-bottom: 10px; /* Espacio debajo del título */
}

.item-circuit p {
  font-size: 1rem; /* Tamaño del texto del párrafo */
  color: #666; /* Color del texto del párrafo */
  line-height: 1.6; /* Altura de línea para mejor legibilidad */
}

.item-circuit .line {
  width: 50px; /* Ancho de la línea */
  height: 3px; /* Alto de la línea */
  background-color: #007bff; /* Color de la línea */
  margin: 10px 0; /* Espacio superior e inferior para la línea */
}
.justify-content-center {
}
.h5mvs{
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
  text-align: center;
  color: #3650b5;
  background-color: #ffffff80;
}
/* Testimonials.css */
.section {
  padding: 60px 0;
}

.gray-bg {
  background-color: #f9f9f9;
}

.section-title {
  margin-bottom: 50px;
}

.section-title h2 {
  font-size: 2.5rem;
  color: #333;
}

.divider {
  width: 50px;
  height: 4px;
  background-color: #e12454;
  margin: 20px auto;
}

.testimonial-block {
  background: #fff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%; /* Ocupa el 100% de la altura del contenedor */
}

.testimonial-thumb {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  border-radius: 50%;
  overflow: hidden;
}

.testimonial-thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Asegura que la imagen cubra todo el área sin distorsionar */
}

.client-info h4 {
  font-size: 1.25rem;
  color: #333;
}

.client-info span {
  display: block;
  font-size: 1rem;
  color: #777;
  margin-bottom: 15px;
}

.client-info p {
  font-size: 0.9rem;
  color: #555;
  margin-top: 10px;
  font-family: cursive;
}

.iconosvg {
  font-size: 60px;
  position: absolute;
  right: 46px;
}

.slick-slide {
  margin: 0 0.5rem; /* Espacio entre slides */
}

.slick-list {
  margin: 0 -0.5rem; /* Ajusta el contenedor para compensar el margen entre slides */
}

.service {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background: none; /* Elimina cualquier fondo existente */
  position: relative;
}

.service .service-item {
    background: #fff;
    padding: 30px;
    border-radius: 5px;
}

.service .icon {
    float: left;
    margin-bottom: 10px;
}
.rowdev{
  display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    align-content: space-between;
    flex-direction: row;
    justify-content: space-evenly;
}
.service i {
    color: #e12454;
    font-size: 3em
}
.service .content {
    clear: both;
    text-align: left;
}
.service h4 {
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 12px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
}


.service-block {
    padding: 20px;
    margin-top: 40px;
    border: 1px solid rgba(0, 0, 0, 0.03);
    box-shadow: 0 0 38px rgba(21, 40, 82, 0.07);
}

.service-block img {
    width: 100%;
    margin-top: -60px;
    border: 5px solid #fff;
}

.department-service {
    margin-bottom: 40px;
}

.department-service li {
    margin-bottom: 10px;
}

.department-service li i {
    color: #e12454;
}
.divider {
    width: 40px;
    height: 5px;
    background: #e12454;
}
.widget .divider {
    height: 3px;
}
.section-title{
  padding-top: 100px;
}
#Servicemac{
  background-color:#0000;
}
.contact-block {
    text-align: center;
    border: 5px solid #eef2f600;
    padding: 50px 25px;
    display: flex; /* Flexbox para alinear contenido */
    flex-direction: column; /* Alinear elementos verticalmente */
    justify-content: center; /* Centrar verticalmente */
    align-items: center; /* Centrar horizontalmente */
    height: 100%; /* Ocupa todo el espacio disponible */
    box-sizing: border-box; /* Incluye el padding y el border en el tamaño total */
}

.col-lg-4, .col-sm-6, .col-md-6 {
    padding: 10px; /* Espaciado alrededor de cada card */
}

.contact-block img {
    max-width: 100%;
    height: auto;
    background-color: #eef2f600;
}

.contact-block h5 {
    margin-top: 20px;
    font-size: 1.25rem; /* Tamaño de fuente para los títulos */
}

.services-grid {
    display: flex;
    flex-wrap: wrap; /* Permite que los elementos se ajusten a múltiples filas */
    justify-content: space-between; /* Espacio uniforme entre los elementos */
    margin-bottom: 30px;
}

.hesectionservice{
  text-align: center;
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 12px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
}
.rowdata{
  display: flex;
  margin-right: -15px;
  margin-left: -15px;
  flex-wrap: wrap;
  align-content: flex-end;
  justify-content: space-around;
}
@media (max-width: 768px) {
    .services-grid {
        flex-direction: column; /* En pantallas más pequeñas, los elementos estarán en una columna */
        align-items: center; /* Centrar elementos en columna */
    }
    .col-lg-4, .col-sm-6, .col-md-6 {
        max-width: 100%; /* Asegura que ocupen todo el ancho disponible en dispositivos pequeños */
    }
    #empresaunid {
      display: none; /* Oculta el elemento cuando la pantalla es menor o igual a 768px */
    }
}
.cta-section {
    margin-bottom: -80px;
}
.cta {

    background-position: center center;
    background-size: cover;
    position: relative;
}.cta:before {
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(34, 58, 102, 0.95);
}
.counter-stat {
    text-align: center;
    padding: 55px 0px 40px 0px;
    position: relative;
}


.counter-stat .icon {
  display: block;
  color: rgba(255, 255, 255, 0.06);
  font-size: 70px;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  -webkit-transform: translateY(25px);
  transform: translateY(25px);
}

.counter-stat span {
    font-size: 70px;
    color: #fff;
}

.counter-stat p {
    margin-bottom: 0px;
    color: rgba(255, 255, 255, 0.7);
}

@media (max-width: 768px) {
  .counter-stat {
    margin-bottom: 20px;
  }
}
.section.about {
  padding: 60px 0;
}

.about-img {
  position: relative;
}

.about-img img {
  width: 100%;
  height: auto;
}
.about-img1 img {
  width: 150%;
  height: auto;
}
.title-color {
  color: #223a66;
}

.about-content {
  padding-left: 15px;
}
a {
    color: #222;
    text-decoration: none;
    transition: all .35s ease;
}
.rowabout{
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  justify-content: center;
}
a:focus,
a:hover {
    color: #e12454;
    text-decoration: none;
}

a:focus {
    outline: none;
}

.appoinment-content {
    position: relative;
    text-align: left;
}

.appoinment-content img {
    width: 85%;
}

.appoinment-content .emergency {
    position: absolute;
    content: "";
    right: 10px;
    bottom: 20px;
    background: #223a66;
    padding: 48px;
}

.appoinment-content .emergency h2 {
    color: #fff;
}

.appoinment-content .emergency i {
    margin-right: 10px;
    color: rgba(255, 255, 255, 0.7);
}

.appoinment-form {
    margin-top: 40px;
}

.appoinment-form .form-control {
    background: #f4f9fc;
    height: 55px;
    border-color: rgba(0, 0, 0, 0.05);
}

.appoinment-form textarea.form-control {
    height: auto;
}
.appoinment-content  .img-fluid{
  width: 510px;
  height: 718px;
}
/* src/components/Banner.css */


.banner {
  position: relative;
  overflow: hidden;
  background: #fff;
  background: none;
  background-size: cover;
  min-height: 550px;
}

.banner .block {
  padding: 80px 0px 160px;
}

.banner .block h1 {
  font-size: 60px;
  line-height: 1.2;
  letter-spacing: -1.2px;
  text-transform: capitalize;
  color: #caeef;
}

@media (max-width: 480px) {
  .banner .block h1 {
    font-size: 38px;
    line-height: 50px;
  }
  .banner {
    min-height: 450px;
    background: #fff !important;
  }
}

@media (max-width: 400px) {
  .banner .block h1 {
    font-size: 28px;
    line-height: 40px;
  }
  .banner {
    min-height: 450px;
    background: #fff !important;
  }
}

@media (max-width: 768px) {
  .banner .block h1 {
    font-size: 56px;
    line-height: 70px;
  }
  .banner {
    background: #fff !important;
  }
}

@media (max-width: 992px) {
  .banner {
    background: #fff !important;
  }
}

.btn {
    display: inline-block;
    font-size: 14px;
    font-size: 0.8125rem;
    font-weight: 700;
    letter-spacing: .5px;
    padding: .75rem 2rem;
    font-family: "Exo", sans-serif;
    text-transform: uppercase;
    border-radius: 5px;
    border: 2px solid transparent;
    transition: all .35s ease;
}

.btn.btn-icon i {
    border-left: 1px solid rgba(255, 255, 255, 0.09);
    padding-left: 15px;
}

.btn:focus {
    outline: 0px;
    box-shadow: none;
}

.btn-main {
    background: #223a66;
    color: #fff;
    border-color: #223a66;
}

.btn-main:hover {
    background: #e12454;
    border-color: #e12454;
    color: #fff;
}

.btn-main-2 {
    background: #e12454;
    color: #fff;
    border-color: #e12454;
}

.btn-main-2:hover {
    background: #223a66;
    color: #fff;
    border-color: #223a66;
}

.btn-solid-border {
    border: 2px solid #223a66;
    background: transparent;
    color: #223a66;
}

.btn-solid-border:hover {
    border: 2px solid #223a66;
    color: #fff;
    background: #223a66;
}

.btn-solid-border:hover.btn-icon i {
    border-left: 1px solid rgba(255, 255, 255, 0.09);
}

.btn-solid-border.btn-icon i {
    border-left: 1px solid rgba(0, 0, 0, 0.09);
}

.btn-transparent {
    background: transparent;
    color: #222;
    border-color: #6F8BA4;
}

.btn-transparent:hover {
    background: #6F8BA4;
    color: #fff;
}

.btn-white {
    background: #fff;
    border-color: #fff;
    color: #222;
}

.btn-white:hover {
    background: #223a66;
    color: #fff;
    border-color: #223a66;
}

.btn-solid-white {
    border-color: #fff;
    color: #fff;
}

.btn-solid-white:hover {
    background: #fff;
    color: #222;
}

.btn-round {
    border-radius: 4px;
}

.btn-round-full {
    border-radius: 50px;
}

.btn.active:focus,
.btn:active:focus,
.btn:focus {
    outline: 0;
}
.banner {
  position: relative;
  overflow: hidden;
  color: #fff; /* Ajusta el color del texto si es necesario */
}

.banner-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Asegura que el video cubra toda el área del contenedor */
  z-index: -1; /* Coloca el video detrás del contenido */
}

.banner-content {
  position: relative;
  z-index: 1; /* Asegura que el contenido esté sobre el video */
  padding: 100px 0; /* Ajusta el relleno según sea necesario para centrar el contenido */
}

.block {
  /* Ajusta el estilo de tu bloque según sea necesario */
}

.btn-container {
  margin-top: 20px; /* Ajusta el margen según sea necesario */
}
.colorh1{
  color: #ffff
}

/* Cambio de color para pantallas fuera de tamaño original */
@media (max-width: 768px) {
  .colorh1 {
    color: #000; /* Cambiar a negro en pantallas más pequeñas */
  }
}

@media (max-width: 480px) {
  .banner .block h1 {
    font-size: 38px;
    line-height: 50px;
  }
  .banner {
    min-height: 450px;
    background: #fff !important;
  }
}

@media (max-width: 400px) {
  .banner .block h1 {
    font-size: 28px;
    line-height: 40px;
  }
  .banner {
    min-height: 450px;
    background: #fff !important;
  }
}

@media (max-width: 768px) {

      .banner .block h1 {
        font-size: 56px;
        line-height: 70px;
      }
      .banner {
        color: #000 !important;
        background: #fff !important;
      }
    }

    .btn {
        display: inline-block;
        font-size: 14px;
        font-size: 0.8125rem;
        font-weight: 700;
        letter-spacing: .5px;
        padding: .75rem 2rem;
        font-family: "Exo", sans-serif;
        text-transform: uppercase;
        border-radius: 5px;
        border: 2px solid transparent;
        transition: all .35s ease;
    }

    .btn.btn-icon i {
        border-left: 1px solid rgba(255, 255, 255, 0.09);
        padding-left: 15px;
    }

    .btn:focus {
        outline: 0px;
        box-shadow: none;
    }

    .btn-main {
        background: #223a66;
        color: #fff;
        border-color: #223a66;
    }

    .btn-main:hover {
        background: #e12454;
        border-color: #e12454;
        color: #fff;
    }

    .btn-main-2 {
        background: #e12454;
        color: #fff;
        border-color: #e12454;
    }

    .btn-main-2:hover {
        background: #223a66;
        color: #fff;
        border-color: #223a66;
    }

    .btn-solid-border {
        border: 2px solid #223a66;
        background: transparent;
        color: #223a66;
    }

    .btn-solid-border:hover {
        border: 2px solid #223a66;
        color: #fff;
        background: #223a66;
    }

    .btn-transparent {
        background: transparent;
        color: #222;
        border-color: #6F8BA4;
    }

    .btn-transparent:hover {
        background: #6F8BA4;
        color: #fff;
    }

    .btn-white {
        background: #fff;
        border-color: #fff;
        color: #222;
    }

    .btn-white:hover {
        background: #223a66;
        color: #fff;
        border-color: #223a66;
    }

    .btn-round {
        border-radius: 4px;
    }

    .btn-round-full {
        border-radius: 50px;
    }

    .btn.active:focus,
    .btn:active:focus,
    .btn:focus {
        outline: 0;
    }

    .banner-video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
    }

    .banner-content {
      position: relative;
      z-index: 1;
      padding: 100px 0;
    }

    .btn-container {
      margin-top: 20px;
    }

/* Cambios para dispositivos específicos */
@media (min-width: 481px) and (max-width: 768px) {
  .colorh1 {
    color: #000; /* Cambiar a negro para tabletas */
  }
}

@media (min-width: 769px) {
  .banner-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; /* Tamaño fijo para pantallas más grandes */
    height: 100%;
    object-fit: cover;
    z-index: -1;
    color: #000;
  }
}

/* Opcional: Estilo para móviles */
@media (max-width: 480px) {
  .banner-video {
    color: #000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; /* Mantenerlo responsivo en móviles */
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
}
/* Estilos generales para el botón de video */
.row360 {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: nowrap;
    margin-right: -15px;
    margin-left: -15px;
}
.play_button_with_text {
  display: inline-block;
  position: relative;
  text-decoration: none;
  color: #fff;
  background-color: #e12454;
  padding: 20px 20px;
  border-radius: 50px;
  font-size: 12px;
  font-weight: bold;
  transition: all 0.3s ease;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Estilo al hacer hover en el botón */
.play_button_with_text:hover {
  background-color: #0c0d49; /* cambiar el color de fondo al hacer hover */
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2);
  transform: translateY(-3px);
  color:#ffff;
}

/* Estilo para el texto del enlace */
.play_button_with_text .link-text p {
  margin: 0;
  font-size: 16px;
  color: #fff;
}

/* Estilos para el SVG (icono de "play") */
.play_button_with_text .play {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}

.play_button_with_text .play svg {
  width: 30px;
  height: 30px;
  fill: #fff;
  transition: fill 0.3s ease;
}

/* Cambiar color del SVG al hacer hover */
.play_button_with_text:hover .play svg {
  fill: #fff700; /* amarillo al hacer hover */
}

/* Estilos para la animación al pasar el mouse */
.play_button_with_text .inner-wrap {
  display: inline-block;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.spinner {
  border: 8px solid #f3f3f3; /* Color de fondo del spinner */
  border-top: 8px solid #3498db; /* Color del spinner */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.preloader p {
  margin-top: 10px;
  color: #ffffff;
  font-size: 1.2em;
}
/* Asegúrate de que el contenedor del banner ocupe todo el espacio */
.banner {
  position: relative;
  width: 100%;
  height: 100vh; /* Ocupa toda la altura del viewport */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

/* Estilo del video de fondo para cubrir todo el banner */
.banner-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Para que quede detrás del contenido */
}

/* Contenedor principal del modelo */
.canvas-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* Preloader estilo centrado */
.preloader {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

/* Spinner básico */
.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

/* Animación del spinner */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Aseguramos que el canvas ocupe todo el espacio disponible */
.canvas-container canvas {
  width: 100% !important;
  height: 100% !important;
}
.Import{
    padding: 10px;
    width: 100%;
    height: 100%;
}
.row360section{
  display: flex;
  flex-wrap: wrap;
}

.card360 {
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  height: 500px; /* Ajusta la altura total del card */
}

.card-header {
  background-color: #f8f9fa;
  padding: 10px 15px;
  font-size: 1.25rem;
  font-weight: bold;
}

.card-body {
  padding: 15px;
}

.cardfoot360 {
  position: absolute;
  bottom: -3%;
  left: 0;
  right: 0;
  background-color: rgb(255, 255, 255);
  padding: 16px;
  text-align: center;
  z-index: 1;
  height: 10%;
}
.card-body-360 {
    display: flex; /* Activa flexbox */
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
    height: 100%; /* Asegúrate de que el contenedor tenga una altura definida */
}
.img-card {
    display: flex; /* Activa flexbox */
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
    height: 100%; /* Asegúrate de que el contenedor tenga una altura definida */
}
/* Global settings */
*,
*::before,
*::after {
  box-sizing: border-box;
}

.s_features_section {
  padding: 60px 5%;
}

.custom_container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 15px;
}

.s_features_item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 60px;

}
.s_features_item_card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Centra las tarjetas */
  align-items: stretch; /* Estira las tarjetas para que tengan la misma altura */
  gap: 20px; /* Espacio entre tarjetas */
  margin-bottom: 60px;

}
.titulos_servicios {
  font-family: "Roboto", sans-serif;
  color: #001d38;
}

.text-azul {
  color: #001d38;
}

.f_p {
  font-family: "Poppins", sans-serif;
}

.f_size_18 {
  font-size: 18px;
}

.f_size_20 {
  font-size: 20px;
}

.f_size_30 {
  font-size: 30px;
}

.f_700 {
  font-weight: 700;
}

.f_400 {
  font-weight: 400;
}

.t_color {
  color: #1e73be;
}

.l_height30 {
  line-height: 30px;
}

.l_height45 {
  line-height: 45px;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.s_features_img {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

@media (max-width: 992px) {
  .pl_120 {
    padding-left: 0;
  }

  .ml_50 {
    margin-left: 0;
  }

  .s_features_img {
    text-align: center;
    max-width: 100%;
    padding: 15px;
  }
}

.elegirnos {
  background-color: #f9f9f9;
  padding: 60px 0;
}

.elegirnos h1 {
  font-family: "Roboto", sans-serif;
  font-size: 36px;
  color: #001d38;
  margin-bottom: 40px;
}

.item {
  margin-bottom: 40px;
}

.card-MaC-SOFTINS {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  flex: 1 1 calc(33.333% - 20px); /* Tres tarjetas en una fila con espaciado */
  max-width: 350px;
  max-height: 400px;
  margin: 20px;
}

.card-MaC-SOFTINS:hover {
  transform: translateY(-10px);
}

.item h3 {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  color: #1e73be;
  margin-top: 20px;
  margin-bottom: 15px;
}

.hr-MaC-SOFTINS {
  width: 50px;
  height: 3px;
  background-color: #1e73be;
  margin-bottom: 20px;
}

.imgCard {
  width: 50%;
}

.lh-18 {
  line-height: 18px;
}


.item {
  margin-bottom: 40px;
}

.item h3 {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  color: #1e73be;
  margin-top: 20px;
  margin-bottom: 15px;
}

.hr-MaC-SOFTINS {
  width: 50px;
  height: 3px;
  background-color: #1e73be;
  margin-bottom: 20px;
}

.lh-18 {
  line-height: 18px;
}

.lh-20 {
  line-height: 20px;
}

.lh-30 {
  line-height: 30px;
}

.wow {
  visibility: visible;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.fadeInRight {
  animation-name: fadeInRight;
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

.imgCard {
  max-width: 100%;
  height: auto;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
/* Global settings */
*,
*::before,
*::after {
  box-sizing: border-box;
}
.s_features_section {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background: none; /* Elimina cualquier fondo existente */
  position: relative;
}

.s_features_section::before {
  content: "";
  position: fixed; /* Fijado para que la imagen de fondo no se mueva al hacer scroll */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://rath.mac-softins.com/imgpublic/oficialpage/sever.webp'); /* Ruta a tu imagen */
  background-size: cover;
  background-position: center center;

  z-index: -1;
}
.s_features_section_vps {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background: none; /* Elimina cualquier fondo existente */
  position: relative;
}

.s_features_section_vps::before {
  content: "";
  position: fixed; /* Fijado para que la imagen de fondo no se mueva al hacer scroll */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://www.wnpower.net/wp-content/uploads/sites/7/2020/10/cloud-vps-ssd.png'); /* Ruta a tu imagen */
  background-size: cover;
  background-position: center center;
  opacity: 0.08;
  z-index: -1;
}
.a_features_section_dev {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background: none; /* Elimina cualquier fondo existente */
  position: relative;
}

.a_features_section_dev::before {
  content: "";
  position: fixed; /* Fijado para que la imagen de fondo no se mueva al hacer scroll */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://rath.mac-softins.com/imgpublic/oficialpage/fondoa.jpeg'); /* Ruta a tu imagen */
  background-size: cover;
  opacity: 0.1;
  background-position: center center;

  z-index: -1;
}
.custom_container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 15px;
}
.listaespecificaciones {
  width: 80%; /* Reduce el ancho al 80% del contenedor padre */
  margin: 0 auto; /* Centra el contenedor horizontalmente */
  padding: 20px; /* Añade un poco de espacio interno */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Añade una sombra ligera para destacar el contenedor */
  border-radius: 10px; /* Redondea los bordes */
  font-size: 14px; /* Ajusta el tamaño de la fuente */
  line-height: 1.6; /* Mejora la legibilidad del texto */
}

.listaespecificaciones li {
  margin-bottom: 10px; /* Espacio entre cada elemento de la lista */
}

.s_features_item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 60px;

}
.s_features_item_card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Centra las tarjetas */
  align-items: stretch; /* Estira las tarjetas para que tengan la misma altura */
  gap: 20px; /* Espacio entre tarjetas */
  margin-bottom: 60px;

}
.titulos_servicios {
  font-family: "Roboto", sans-serif;
  color: #001d38;
}

.text-azul {
  color: #001d38;
}

.f_p {
  font-family: "Poppins", sans-serif;
}

.f_size_18 {
  font-size: 18px;
}

.f_size_20 {
  font-size: 20px;
}

.f_size_30 {
  font-size: 30px;
}

.f_700 {
  font-weight: 700;
}

.f_400 {
  font-weight: 400;
}

.t_color {
  color: #e12454;
}

.l_height30 {
  line-height: 30px;
}

.l_height45 {
  line-height: 45px;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.s_features_img {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

@media (max-width: 992px) {
  .pl_120 {
    padding-left: 0;
  }

  .ml_50 {
    margin-left: 0;
  }

  .s_features_img {
    text-align: center;
    max-width: 100%;
    padding: 15px;
  }
}

.elegirnos {
  background-color: #f9f9f9;
  padding: 60px 0;
}

.elegirnos h1 {
  font-family: "Roboto", sans-serif;
  font-size: 36px;
  color: #001d38;
  margin-bottom: 40px;
}

.item {
  margin-bottom: 40px;
}

.card-MaC-SOFTINS {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  flex: 1 1 calc(33.333% - 20px); /* Tres tarjetas en una fila con espaciado */
  max-width: 350px;
  max-height: 400px;
  margin: 20px;
}

.card-MaC-SOFTINS:hover {
  transform: translateY(-10px);
}

.item h3 {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  color: #1e73be;
  margin-top: 20px;
  margin-bottom: 15px;
}

.hr-MaC-SOFTINS {
  width: 50px;
  height: 3px;
  background-color: #1e73be;
  margin-bottom: 20px;
}

.imgCard {
  width: 50%;
}

.lh-18 {
  line-height: 18px;
}


.item {
  margin-bottom: 40px;
}

.item h3 {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  color: #1e73be;
  margin-top: 20px;
  margin-bottom: 15px;
}

.hr-MaC-SOFTINS {
  width: 50px;
  height: 3px;
  background-color: #1e73be;
  margin-bottom: 20px;
}

.lh-18 {
  line-height: 18px;
}

.lh-20 {
  line-height: 20px;
}

.lh-30 {
  line-height: 30px;
}

.wow {
  visibility: visible;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.fadeInRight {
  animation-name: fadeInRight;
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

.imgCard {
  max-width: 100%;
  height: auto;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}


/* Asegura que los elementos estén en una sola línea y tengan el mismo tamaño */
.rowit.separation {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}
.rowdev{
  display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    align-content: space-between;
    flex-direction: row;
    justify-content: space-evenly;
}
/* Asegura que las tarjetas tengan el mismo tamaño */
.card {
  flex: 1; /* Hace que todas las tarjetas tengan el mismo ancho */
  max-width: 18%; /* Ajusta el máximo ancho según la cantidad de tarjetas */
  box-sizing: border-box; /* Incluye padding y borde en el ancho total del elemento */
  background: #fff; /* Fondo blanco opcional para mejorar la visibilidad */
  padding: 10px; /* Espaciado interno para los elementos de la tarjeta */
  border-radius: 8px; /* Bordes redondeados opcionales para mejorar el diseño */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra para las tarjetas */
  text-align: center; /* Alinea el texto al centro */
}

/* Ajusta el tamaño de la imagen dentro de las tarjetas */
.car-img figure {
  margin: 0; /* Elimina el margen por defecto del <figure> */
}.s_features_section_server {
  width: 90%;
  margin: 0 auto; /* Centra la sección dentro del contenedor padre */
  text-align: center; /* Centra el texto dentro de la sección */
}
.a_features_section_dev_server{
  width: 90%;
  margin: 0 auto; /* Centra la sección dentro del contenedor padre */
  text-align: center; /* Centra el texto dentro de la sección */
}
.s_features_section_server_hiper {
  width: 90%;
  margin: 0 auto; /* Centra la sección dentro del contenedor padre */
  text-align: center; /* Centra el texto dentro de la sección */
}

.row.separation-hiper {
  display: flex;
  flex-wrap: wrap; /* Permite que las tarjetas se ajusten a la siguiente línea si el espacio es insuficiente */
  justify-content: space-between; /* Distribuye el espacio entre las tarjetas */
  gap: 20px; /* Espacio entre columnas */
}

.card-hiper {
  flex: 1 1 calc(50% - 20px); /* Cada tarjeta ocupa el 50% del ancho del contenedor menos el espacio entre columnas */
  box-sizing: border-box; /* Incluye padding y borde en el ancho total del elemento */
  background: #fff; /* Fondo blanco opcional para mejorar la visibilidad */
  padding: 20px; /* Espaciado interno para los elementos de la tarjeta */
  border-radius: 8px; /* Bordes redondeados opcionales para mejorar el diseño */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra para las tarjetas */
  text-align: center; /* Alinea el texto al centro */
  max-width: calc(50% - 20px); /* Asegura que las tarjetas no excedan el 50% del contenedor */
  margin-bottom: 20px; /* Espacio debajo de cada tarjeta */
}

.car-img figure {
  margin: 0; /* Elimina el margen por defecto del <figure> */
}
.benefits-section {
  text-align: center;
  padding: 20px;
}

.benefits-section h2 {
  margin-bottom: 30px;
  font-size: 28px;
  text-transform: uppercase;
}

.benefits-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.benefit-box {
  width: 30%;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transition: transform 0.3s ease-in-out;
}

.benefit-box:hover {
  transform: translateY(-10px);
}

.icon-container {
  font-size: 20px;
  margin-bottom: 15px;
}

.benefit-box h4 {
  font-size: 15px;
  margin-bottom: 5px;
}

.benefit-box p {
  font-size: 12px;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .benefit-box {
    width: 100%;
  }
}

img {
  max-width: 100%; /* Asegura que las imágenes no excedan el ancho del contenedor */
  height: auto; /* Mantiene la proporción de aspecto de la imagen */
}
.desccripcion{
  text-align: justify;
}
.descripcion > li{
  background-color: red
}
.card-hiper p {
  margin: 10px 0; /* Espaciado superior e inferior del texto */
  overflow: hidden; /* Evita que el texto se salga del contenedor */
  text-overflow: ellipsis; /* Muestra puntos suspensivos si el texto es demasiado largo */
  white-space: nowrap; /* Impide que el texto se envuelva en múltiples líneas */
}
@media (max-width: 768px) {
  .card {
    flex: 1 1 calc(50% - 20px); /* Cada tarjeta ocupa el 50% del ancho del contenedor menos el espacio entre columnas */
    max-width: calc(50% - 20px); /* Asegura que las tarjetas no excedan el 50% del contenedor */
  }
}

/* Media queries para teléfonos móviles */
@media (max-width: 576px) {
  .card {
    flex: 1 1 100%; /* Cada tarjeta ocupa el 100% del ancho del contenedor */
    max-width: 100%; /* Asegura que las tarjetas no excedan el 100% del contenedor */
  }
}
.domain-search-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.domain-search-container input {
  width: 100%; /* Ancho del input */
  padding: 10px;
  border: 1px solid #ccc; /* Borde gris claro */
  border-radius: 20px; /* Bordes redondeados */
  outline: none; /* Sin borde de enfoque */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Sombra sutil */
  transition: border-color 0.3s; /* Transición suave */
}

.domain-search-container input:focus {
  border-color: #4285f4; /* Color del borde al enfocar */
}

.domain-search-container button {
  padding: 10px 20px; /* Espaciado del botón */
  margin-left: 10px; /* Espacio entre el input y el botón */
  background-color: #4285f4; /* Color de fondo del botón */
  color: white; /* Color del texto */
  border: none; /* Sin borde */
  border-radius: 20px; /* Bordes redondeados */
  cursor: pointer; /* Cambiar cursor al pasar el mouse */
  transition: background-color 0.3s; /* Transición suave */
}

.domain-search-container button:hover {
  background-color: #357ae8; /* Color de fondo al pasar el mouse */
}
.Banner_img{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: space-around;
      align-items: center;
      flex-direction: column;
}
/* Ajustes de las tarjetas en pantallas más pequeñas */
@media screen and (max-width: 768px) {
  .card {
    max-width: 100%; /* Ocupa el ancho completo en pantallas medianas */
  }
}

@media screen and (max-width: 576px) {
  .rowit {
    flex-direction: column; /* Cambiar a diseño vertical en pantallas muy pequeñas */
    align-items: center; /* Centrar las tarjetas */
  }

  .card {
    width: 100%; /* Ancho completo en pantallas pequeñas */
    margin-bottom: 20px;
  }
}

/* Media Queries */
@media (max-width: 768px) {
  .box {
    margin: 20px 0;
    padding: 20px;
  }

  h2.titulos_servicios {
    font-size: 24px;
  }

  h4.lh-20 {
    font-size: 18px;
  }

  .domain-search-container {
    flex-direction: column;
    align-items: center;
  }

  input.form-control {
    margin-bottom: 10px;
  }
}
.price-text{
  font-size: 12px;
}

.feature-section {
  padding: 60px 0;
  background-color: #f9f9f9;
}

.section-title {
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 40px;
  animation: fadeInUp 0.5s;
}

.feature-box {
  margin-bottom: 30px;
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.feature-box:hover {
  transform: translateY(-10px);
}

.feature-inner {
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}

.feature-icon {
  font-size: 40px;
  color: #3498db;
  margin-bottom: 15px;
}

.feature-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.feature-description {
  font-size: 16px;
  color: #7a7a7a;
}

/* Animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Media Queries */
@media (max-width: 768px) {
  .feature-box {
    margin-bottom: 20px;
  }
}

@media (max-width: 576px) {
  .feature-title {
    font-size: 18px;
  }

  .feature-description {
    font-size: 14px;
  }

  .feature-icon {
    font-size: 35px;
  }
}
.descripcion .badge {
  display: inline-block;
  padding: 0.25rem 1rem; /* px-4 py-1 */
  border-radius: 1rem; /* rounded-xl */
  background-color: #3b82f6; /* bg-blue-500 */
  color: white; /* text-white */
  font-size: 0.75rem; /* text-xs */
  margin-bottom: 1.5rem; /* mb-6 */
}

@media (min-width: 768px) {
  .descripcion .badge {
    font-size: 0.875rem; /* md:text-sm */
  }
}
.k-link{
  color: #ffff;
}
#btn-round-full-k1 {
    color: #ffff;
    margin-top: -0.4%;
    border-radius: 50px;
}

.color-1 {	background: #435a6b;}
#nav a {
	position: relative;
	display: inline-block;
	margin: 15px 15px;
	outline: none;
	color: #fff;
	text-decoration: none;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 100;
	text-shadow: 0 0 1px rgba(255,255,255,0.3);
	font-size: 1em;
}

#nav a:hover,
#nav a:focus {
	outline: none;
}.cl-effect-1 a::before,
.cl-effect-1 a::after {
	display: inline-block;
	opacity: 0;
	-webkit-transition: -webkit-transform 0.3s, opacity 0.2s;
	-moz-transition: -moz-transform 0.3s, opacity 0.2s;
	transition: transform 0.3s, opacity 0.2s;
}

.cl-effect-1 a::before {
	margin-right: 10px;
	content: '[';
	-webkit-transform: translateX(20px);
	-moz-transform: translateX(20px);
	transform: translateX(20px);
}

.cl-effect-1 a::after {
	margin-left: 10px;
	content: ']';
	-webkit-transform: translateX(-20px);
	-moz-transform: translateX(-20px);
	transform: translateX(-20px);
}

.cl-effect-1 a:hover::before,
.cl-effect-1 a:hover::after,
.cl-effect-1 a:focus::before,
.cl-effect-1 a:focus::after {
	opacity: 1;
	-webkit-transform: translateX(0px);
	-moz-transform: translateX(0px);
	transform: translateX(0px);
}
/* Grid para las tarjetas (cards) */
.card-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Cuatro columnas */
  gap: 20px; /* Espacio entre las tarjetas */
}

/* Estilo de las tarjetas (cards) */
.category-card {
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.category-card:hover {
  transform: scale(1.05); /* Efecto de zoom en hover */
}

.card-content {
  padding: 20px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Estilo para la imagen dentro de la tarjeta */
.imgCard {
  width: 100%; /* La imagen ocupa el 100% del ancho del contenedor */
  height: auto; /* Mantiene la proporción de la imagen */
  object-fit: cover; /* Ajusta la imagen para cubrir el área sin distorsionarse */
  border-bottom: 1px solid #ddd; /* Línea divisoria */
  margin-bottom: 10px; /* Espacio entre la imagen y el texto */
}

/* Estilo para el texto dentro de las tarjetas */
.card-content p {
  margin: 10px 0;
  font-size: 16px;
  font-weight: bold; /* Hace que el texto sea más llamativo */
}

.card-content strong {
  font-weight: bold;
}
.category-card-pro {
  position: relative; /* Para posicionar el botón */
  overflow: hidden; /* Para evitar que el botón sobresalga de la tarjeta */
  border-radius: 8px; /* Bordes redondeados para una apariencia más suave */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Sombra suave */
  transition: transform 0.3s; /* Transición suave al pasar el mouse */
}

.category-card-pro:hover {
  transform: scale(1.02); /* Aumenta el tamaño ligeramente al pasar el mouse */
}

.card-content {
  position: relative;
  padding: 16px; /* Espaciado interno */
}

.add-to-cart-button {
  display: none; /* Oculta el botón por defecto */
  position: absolute; /* Para posicionarlo */
  bottom: 20px; /* Ajusta según sea necesario */
  left: 50%; /* Centrar horizontalmente */
  transform: translateX(-50%); /* Ajuste para centrar */
  background-color: rgba(34, 34, 34, 0.8); /* Fondo oscuro con opacidad */
  color: white; /* Color del texto */
  border: none; /* Sin borde */
  border-radius: 5px; /* Bordes redondeados */
  padding: 10px 20px; /* Espaciado interno */
  font-size: 16px; /* Tamaño de fuente */
  cursor: pointer; /* Cambia el cursor al pasar sobre el botón */
  transition: background-color 0.3s, transform 0.3s; /* Transiciones suaves */
}

.add-to-cart-button:hover {
  background-color: rgba(34, 34, 34, 1); /* Cambia a un fondo oscuro sólido al pasar el mouse */
  transform: scale(1.05); /* Aumenta el tamaño del botón al pasar el mouse */
}

.category-card-pro:hover .add-to-cart-button {
  display: block; /* Muestra el botón al pasar el mouse */
}
.container {
  padding: 20px;
}

.search-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.search-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #dcdcdc;
  border-radius: 20px;
  font-size: 16px;
  outline: none;
  transition: border 0.3s;
}

.search-input:focus {
  border: 1px solid #4285f4;
}

.search-button {
  background-color: #4285f4;
  border: none;
  border-radius: 20px;
  color: white;
  padding: 10px 15px;
  margin-left: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.search-card {
  position: relative;
  background-color: #4285f4; /* Color de fondo del botón */
  border: none;
  border-radius: 50%; /* Borde redondeado para forma circular */
  padding: 10px;
  color: white; /* Color del icono */
  font-size: 20px; /* Tamaño del icono */
  cursor: pointer;
  transition: background-color 0.3s;
}

.search-card:hover {
  background-color: #357ae8; /* Color de fondo al pasar el mouse */
}

.badge-success {
  position: absolute;
  top: -5px; /* Ajusta la posición vertical */
  right: -10px; /* Ajusta la posición horizontal */
  background-color: #34a853; /* Color de fondo de la etiqueta */
  color: white; /* Color del texto */
  border-radius: 50%; /* Forma circular */
  padding: 5px 8px; /* Espaciado de la etiqueta */
  font-size: 14px; /* Tamaño de la etiqueta */
  font-weight: bold; /* Negrita para destacar la cantidad */
  min-width: 20px; /* Ancho mínimo para asegurar visibilidad */
  text-align: center; /* Centrar texto */
}
.badge-marca {
  background-color: #34a853; /* Color de fondo de la etiqueta */
  color: white; /* Color del texto */
  padding: 5px 10px; /* Espaciado de la etiqueta (ajustar según sea necesario) */
  font-size: 14px; /* Tamaño de la fuente */
  font-weight: bold; /* Negrita para destacar la cantidad */
  min-width: 20px; /* Ancho mínimo para asegurar visibilidad */
  text-align: center; /* Centrar el texto */
  display: inline-flex; /* Para ajustar contenido dentro del badge */
  justify-content: center; /* Centra horizontalmente el contenido */
  align-items: center; /* Centra verticalmente el contenido */
  line-height: 1; /* Asegura que el texto no se salga del contenedor */
  white-space: nowrap; /* Evita que el texto se rompa en varias líneas */
  overflow: hidden; /* Oculta cualquier desbordamiento de texto */
  text-overflow: ellipsis; /* Agrega "..." si el texto es muy largo */
}
.search-button:hover {
  background-color: #357ae8;
}
/* Estilo del overlay del modal */
/* Añade estos estilos a tu archivo CSS para asegurarte de que el modal sea visible */
.shopping-cart-container {
  position: fixed; /* Fija el carrito en la pantalla */
  left: 0; /* Coloca el carrito en el lado izquierdo */
  top: 0; /* Empieza desde la parte superior */
  width: 300px; /* Ancho del carrito */
  height: 100%; /* Altura completa */
  background-color: white; /* Fondo blanco */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5); /* Sombra para un efecto de profundidad */
  z-index: 1000; /* Asegúrate de que esté por encima de otros elementos */
  overflow-y: auto; /* Permitir desplazamiento vertical */
  transition: transform 0.3s ease; /* Transición suave al mostrar/ocultar */
}

.shopping-cart-form {
  padding: 20px; /* Espaciado interno */
  border-radius: 8px; /* Esquinas redondeadas */
  background: #f9f9f9; /* Fondo de tarjeta */
}
.container300 {
  max-height: 250px; /* Altura máxima del contenedor */
  overflow-y: auto; /* Permite el desplazamiento vertical */
  border: 1px solid #ccc; /* Opcional: borde alrededor del contenedor */
  padding: 10px; /* Opcional: espaciado interno */
  background-color: #f9f9f9; /* Opcional: color de fondo */
}

.shopping-cart-form h2 {
  margin-bottom: 20px; /* Espaciado debajo del título */
}

.shopping-cart-form table {
  width: 100%; /* Asegúrate de que la tabla use todo el ancho */
  border-collapse: collapse; /* Colapsar bordes para un mejor diseño */
}

.shopping-cart-form th, .shopping-cart-form td {
  border: 1px solid #ccc; /* Bordes de tabla */
  padding: 8px; /* Espaciado en celdas */
  text-align: left; /* Alinear texto a la izquierda */
}

.shopping-cart-form button {
  margin-top: 10px; /* Margen superior para separación */
}
/* Diseño responsivo para las tarjetas */
@media (max-width: 900px) {
  .card-grid {
    grid-template-columns: repeat(2, 1fr); /* Dos columnas en pantallas medianas */
  }
}

@media (max-width: 600px) {
  .card-grid {
    grid-template-columns: 1fr; /* Una columna en pantallas pequeñas */
  }
}
.category-card {
  cursor: pointer; /* Cambia el cursor para indicar que es clickeable */
}

.card-details {
  padding: 10px; /* Espaciado para los detalles adicionales */
  background-color: #f9f9f9; /* Color de fondo para los detalles */
  border: 1px solid #ccc; /* Borde alrededor de los detalles */
  margin-top: 10px; /* Espacio entre la tarjeta y los detalles */
}
.ClassFooter{/* Ancho mínimo para asegurar visibilidad */
  text-align: center;
}
.btnButton {
  background-color: #4CAF50; /* Color de fondo verde */
  color: white; /* Color del texto */
  border: none; /* Sin borde */
  border-radius: 5px; /* Esquinas redondeadas */
  padding: 10px 20px; /* Espaciado interno */
  font-size: 16px; /* Tamaño de fuente */
  cursor: pointer; /* Cambia el cursor al pasar el mouse */
  transition: background-color 0.3s, transform 0.2s; /* Transiciones suaves */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Sombra sutil */
  margin-right: 10px;
}

/* Efecto al pasar el mouse */
.btnButton:hover {
  background-color: #45a049; /* Cambia el color de fondo */
  transform: scale(1.05); /* Aumenta ligeramente el tamaño */
}

/* Efecto al hacer clic */
.btnButton:active {
  transform: scale(0.95); /* Reduce ligeramente el tamaño */
}
.BtnDanger {
  background-color: #f44336; /* Color de fondo rojo */
  color: white; /* Color del texto */
  border: none; /* Sin borde */
  border-radius: 5px; /* Esquinas redondeadas */
  padding: 10px 20px; /* Espaciado interno */
  font-size: 16px; /* Tamaño de fuente */
  cursor: pointer; /* Cambia el cursor al pasar el mouse */
  transition: background-color 0.3s, transform 0.2s; /* Transiciones suaves */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Sombra sutil */
  margin-left: 10px;
}

/* Efecto al pasar el mouse */
.BtnDanger:hover {
  background-color: #e53935; /* Cambia el color de fondo */
  transform: scale(1.05); /* Aumenta ligeramente el tamaño */
}

/* Efecto al hacer clic */
.BtnDanger:active {
  transform: scale(0.95); /* Reduce ligeramente el tamaño */
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Asegúrate de que esté por encima de otros elementos */
}

.modal-content {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  max-width: 500px; /* Tamaño máximo del modal */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

/* Estilos para cerrar el modal */
.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 18px;
}
.btn-danger-modal{
	background-color: #000;
	border-radius: 50px;
}
.cabecera {
  padding: 5%;
  display: flex; /* Utiliza flexbox */
  justify-content: flex-end; /* Alinea el contenido a la derecha */
}

/* Estilos responsivos */
@media (max-width: 600px) {
    .modal-content {
        width: 100%; /* Ancho completo en dispositivos móviles */
        padding: 15px; /* Espaciado reducido */
    }

    .modal-content h2 {
        font-size: 1.5em; /* Tamaño de fuente más pequeño */
    }

    .modal-content p {
        font-size: 1em; /* Tamaño de fuente más pequeño */
    }
}
.form_wizard {
  display: flex;
  flex-direction: column;
}

.wizard_steps {
  display: flex;
  list-style: none;
  padding: 0;
}

.wizard_steps li {
  flex: 1;
  text-align: center;
}

.wizard_steps a {
  display: block;
  padding: 10px;
  text-decoration: none;
}

.stepContainer {
  margin-top: 20px;
}

.content {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.actionBar {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

/* Responsividad */
@media (max-width: 768px) {

  .wizard_steps li {
    margin-bottom: 10px;
  }
}
.step_no{
	background-color: #141b20;
	color:#fff;
	padding: 5px;
	border-radius: 50%; /* Hace que el elemento sea completamente redondo */
	width: 40px; /* Ajusta el ancho del cuadrado */
	height: 40px; /* Ajusta la altura del cuadrado */
	display: inline-block; /* Asegura que el elemento mantenga su tamaño */
	text-align: center; /* Centra el contenido horizontalmente */
	line-height: 30px; /* Ajusta para centrar el contenido verticalmente */
}
.step_no:hover{
	background-color: #141b20a1;
	color:#fff;
	padding: 5px;
	border-radius: 50%; /* Hace que el elemento sea completamente redondo */
	width: 40px; /* Ajusta el ancho del cuadrado */
	height: 40px; /* Ajusta la altura del cuadrado */
	display: inline-block; /* Asegura que el elemento mantenga su tamaño */
	text-align: center; /* Centra el contenido horizontalmente */
	line-height: 30px; /* Ajusta para centrar el contenido verticalmente */
}
.with-text{
	font-size: 70%
}
.tabla_cabeccera{
	background-color: #141b20;
	color:#fff;
	text-align: center
}
.form-control-k-m{
		text-align: center;
    display: block;
    width: 69%;
    height: calc(0.5em + 0.75rem + 4px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #1d1d1d;
    background-color: #28a7454d;
    background-clip: padding-box;
    border: 1px solid #141b20;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.StepTitle{
	text-align: center;
}
.resolucion{
	text-align: center;
}
.btn.disabled, .btn:disabled {
    opacity: -3.35;
}
/* qrpayment.css */
.qr-payment {
  background-color: #fff;
  height: 120vh; /* Ocupa toda la altura de la ventana */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.qr-payment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.85); /* Fondo semi-transparente */
  width: 100%;
  max-width: 400px; /* Máximo tamaño del contenedor */
  margin: 20px auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  text-align: center; /* Centra el contenido */
}

.qr-code img {
  width: 250px;
  height: 300px;
  object-fit: cover;
  margin-bottom: 20px;
  border-radius: 8px;
}

.upload-form {
  width: 100%;
}

.upload-form h3 {
  margin-bottom: 20px;
  font-size: 18px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.form-group input[type="file"],
.form-group input[type="text"] {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 0 auto;
  border-radius: 50px;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.btn-submit {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}

.btn-submit:hover {
  background-color: #45a049;
}

.upload-status {
  margin-top: 15px;
  font-size: 14px;
  color: green;
}

.regualdor {
  text-align: center;
  margin-top: 20px;
}

.imgregulador {
  width: 50%; /* Ajuste para que la imagen no sea muy grande */
  max-width: 200px; /* Máximo tamaño de la imagen */
  height: auto;
  margin: 0 auto;
  display: block;
}
.imgreguladorasfi{
  width: 20%; /* Ajuste para que la imagen no sea muy grande */
  max-width: 100px; /* Máximo tamaño de la imagen */
  height: auto;
  margin: 0 auto;
  display: block;
}
/* Responsividad */
@media (max-width: 768px) {
  .qr-payment-container {
    width: 90%;
  }

  .imgregulador {
    width: 70%;
  }
}

@media (max-width: 480px) {
  .qr-payment-container {
    width: 100%;
    padding: 15px;
  }

  .imgregulador {
    width: 80%;
  }
}
/* Container for all cards */
.rowcard {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Space between cards */
  justify-content: center; /* Center align the cards */
  padding: 0 10px; /* Ajuste de padding para el contenedor */
}

/* Style for individual card */
.card {
  flex: 1 1 100%; /* Full width on smaller screens */
  max-width: 500px; /* Max width of each card */
  text-align: center;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

/* Hover effect for cards */
.card:hover {
  transform: translateY(-5px);
}

/* Styles for the main section */
main {
  flex: 1;
  padding: 20px;
  background-color: white;
}

/* Profile image styles */
main > img {
  border-radius: 50%;
  width: 140px;
  height: 140px;
  margin-inline: auto;
  margin-bottom: 1.5rem;
  outline: 1px solid #0C4A6E;
  outline-offset: 2px;
}

/* Card footer styles */
.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #f8f8f8;
  border-top: 1px solid #e0e0e0;
}

/* Card statistics styles */
.card-stat {
  display: flex;
  align-items: center;
  gap: 5px;
}

/* Icons in statistics */
.card-stat i {
  font-size: 20px;
  color: #0C4A6E; /* Example color */
}

.card-stat span {
  font-size: 16px;
  color: #333; /* Example color */
}

/* Primary button styles */
.btn-primary {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #0C4A6E; /* Example color */
  border: none;
  border-radius: 50px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Button hover effect */
.btn-primary:hover {
  background-color: #083f5b; /* Darker color on hover */
}

/* Blog button styles */
.btn-primary-blog {
  border-radius: 50px;
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #d8dfe7;
  color: #fff;
}

/* Main container for two columns */
.blog-wrap {
  display: flex;
  flex-wrap: wrap; /* Allows elements to adapt on smaller screens */
}

/* Blog content container */
.col-lg-8 {
  flex: 1; /* Allows content to occupy available space */
  padding-right: 20px; /* Adjusts space between content and sidebar */
  min-width: 60%; /* Ensures minimum width for content */
}

/* Sidebar container */
.col-lg-4 {
  flex: 0 0 300px; /* Fixed width for sidebar */
  margin-top: 10px; /* Adjust to eliminate sticky margin issues */
}

/* Sidebar wrap */
.sidebar-wrap {
  position: -webkit-sticky;
  position: sticky;
  top: 10%; /* Distance from top of the screen */
  align-self: flex-start; /* Aligns sidebar to the start of the main container */
  height: auto;
  padding: 20px;
}

/* Social media section */
.s_blog_social {
  margin-top: 30px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  text-align: center;
}

/* Social media title */
.s_blog_social h3 {
  font-size: 20px;
  margin-bottom: 20px;
  color: #333;
  font-weight: bold;
}

/* Social media list */
.s_blog_social ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 20px;
}

/* Social media list item */
.s_blog_social ul li {
  display: inline-block;
}

/* Social media link */
.s_blog_social ul li a {
  display: flex;
  align-items: center;
  color: #555;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Social media link icon */
.s_blog_social ul li a i {
  margin-right: 10px;
  font-size: 18px;
}

/* Social media link hover effect */
.s_blog_social ul li a:hover {
  background-color: #fff;
  color: #e12454;
}

/* Custom colors for each social media */
.s_blog_social ul li a .fa-facebook {
  color: #3b5998;
}

.s_blog_social ul li a .fa-twitter {
  color: #1da1f2;
}

.s_blog_social ul li a .fa-linkedin {
  color: #0077b5;
}

.s_blog_social ul li a .fa-whatsapp {
  color: #25d366;
}

/* Responsive: smaller screens */
@media (max-width: 768px) {
  .s_blog_social ul {
    flex-direction: column;
    gap: 15px;
  }

  .s_blog_social ul li a {
    justify-content: center;
    font-size: 14px;
    padding: 10px;
  }

  .card {
    flex: 1 1 100%; /* Full width card on smaller screens */
  }
}

/* Larger screens */
@media (min-width: 1024px) {
  .card {
    flex: 1 1 calc(33.33% - 40px); /* Three cards per row on larger screens */
  }
}

/* Comments section */
.comments-section {
  margin-top: 20px; /* Margin to separate from other elements */
}

/* Individual comment style */
.comment {
  display: flex; /* Use flexbox to align image and comment content */
  align-items: flex-start; /* Align elements at the start */
  border: 1px solid #e0e0e0; /* Soft border color */
  border-radius: 5px; /* Rounded borders */
  padding: 15px; /* Internal spacing */
  margin-bottom: 15px; /* Space between comments */
  background-color: #f9f9f9; /* Light background color */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

/* Comment hover effect */
.comment:hover {
  background-color: #f1f1f1; /* Change background color on hover */
}

/* Comment avatar style */
.comment-avatar {
  width: 40px; /* Avatar width */
  height: 40px; /* Avatar height */
  border-radius: 50%; /* Rounded borders for a circular effect */
  margin-right: 15px; /* Space between image and comment content */
  object-fit: cover; /* Ensure image fits without distortion */
}

/* Comment content style */
.comment-content {
  flex-grow: 1; /* Allow comment content to occupy remaining space */
}

/* Comment name style */
.comment-name {
  font-weight: bold; /* Bold name */
  color: #007bff; /* Blue color for link */
  text-decoration: none; /* No underline for link */
}

/* Comment name hover effect */
.comment-name:hover {
  text-decoration: underline; /* Underline on hover */
}

/* Comment text style */
.comment-text {
  margin-top: 5px; /* Margin to separate comment from name */
  color: #666; /* Lighter text color for comment */
  font-size: 0.9em; /* Standard font size for comment */
}

/* Badge styles */
.badge {
  display: inline-block;
  color: white; /* White text color */
  padding: 0.5rem 1rem; /* Internal spacing (padding) */
  border-radius: 0.25rem; /* Rounded borders */
  font-size: 0.875rem; /* Font size */
  margin-bottom: 0.5rem; /* Bottom spacing */
}

.badge_anual {
  display: inline-block;
  background-color: #46cdb9; /* Background color */
  color: white; /* White text color */
  padding: 0.5rem 1rem; /* Internal spacing (padding) */
  border-radius: 0.25rem; /* Rounded borders */
  font-size: 0.875rem; /* Font size */
  margin-bottom: 0.5rem; /* Bottom spacing */
}
.view-noticia {
  padding: 20px;
}

.row {
  display: flex;
  flex-wrap: wrap; /* Permitir que los elementos se envuelvan en pantallas más pequeñas */
}

.news-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 15px;
  flex: 1 1 300px; /* Hacer que las tarjetas sean flexibles y tengan un ancho mínimo de 300px */
  display: flex;
  flex-direction: column; /* Alinear el contenido verticalmente */
}

.news-image {
  display: flex;
  justify-content: center; /* Centrar la imagen horizontalmente */
  align-items: center; /* Centrar la imagen verticalmente */
  width: 100%;
  padding: 10px; /* Agregar un poco de espacio alrededor de la imagen */
}


.news-content {
  padding: 20px;
}

.news-description {
  line-height: 1.5; /* Mejora la legibilidad */
}

.sidebar-wrap {
  margin-top: 30px; /* Espaciado superior para la barra lateral */
}

.sidebar-widget h5 {
  font-size: 1.25rem; /* Ajuste del tamaño de la fuente */
  margin-bottom: 10px; /* Espacio inferior para el título de la barra lateral */
}

.sidebar-widget ul {
  padding-left: 0; /* Eliminar padding de la lista */
}

.sidebar-widget ul li {
  margin-bottom: 10px; /* Espaciado entre elementos de la lista */
}

.sidebar-widget ul li a {
  color: #333; /* Color del texto de los enlaces */
  text-decoration: none; /* Sin subrayado en enlaces */
}

.sidebar-widget ul li a:hover {
  text-decoration: underline; /* Subrayar en hover */
}
.historia-container {
  padding: 20px;
  background-color: #f0f0f0; /* Fondo de la sección */
}

.historia-content {
  padding: 20px;
  margin-bottom: 40px;
  background-image: url('https://i.pinimg.com/564x/60/a6/b4/60a6b418f2d94090270ef2014ac107ce.jpg');
  background-size: cover; /* Hace que la imagen cubra todo el contenedor */
  background-position: center; /* Centra la imagen en el contenedor */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.timeline-container {
  padding: 20px;
}

.timeline {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
}

.timeline::before {
  content: '';
  position: absolute;
  width: 4px;
  background-color: #333;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -2px;
}

.timeline-item {
  position: relative;
  margin: 20px 0;
  width: 50%;
  padding: 10px 20px;
  box-sizing: border-box;
}

.timeline-item.left {
  left: 0;
  text-align: right;
}

.timeline-item.right {
  left: 50%;
  text-align: left;
}

.timeline-item .content {
  background-color: #27ae60;
  padding: 10px 15px;
  border-radius: 5px;
  color: white;
  position: relative;
}

.timeline-item.left .content::before {
  content: '';
  position: absolute;
  top: 10px;
  right: -15px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent transparent #27ae60;
}

.timeline-item.right .content::before {
  content: '';
  position: absolute;
  top: 10px;
  left: -15px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent #27ae60 transparent transparent;
}

.year {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

/* RESPONSIVE DESIGN */

/* Para pantallas de tamaño medio o más pequeño */
@media (max-width: 768px) {
  .timeline::before {
    left: 10px; /* La línea del timeline se mueve hacia el borde izquierdo */
  }

  .timeline-item {
    width: 100%;
    padding-left: 40px; /* Aumenta el espacio entre la línea y los elementos */
  }

  .timeline-item.left,
  .timeline-item.right {
    left: 0;
    text-align: left;
  }

  .timeline-item.left .content::before,
  .timeline-item.right .content::before {
    left: -15px;
    right: auto;
    border-color: transparent #27ae60 transparent transparent;
  }
}.card-serve {
  width: 300px; /* Ancho fijo para todos los cards */
  height: 500px; /* Altura fija para todos los cards */
  margin: 10px; /* Espaciado entre los cards */
  display: flex; /* Utilizar flexbox para alinear contenido */
  flex-direction: column; /* Organizar los elementos en columna */
  justify-content: space-between; /* Distribuir espacio entre los elementos */
  border: 1px solid #ddd; /* Bordes opcionales */
  border-radius: 8px; /* Bordes redondeados */
  box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Sombra opcional */
  overflow: hidden; /* Evitar desbordamiento de contenido */
}

.car-img {
  flex: 1; /* Permitir que la imagen tome el espacio disponible */
  display: flex; /* Utilizar flexbox para centrar la imagen */
  justify-content: center; /* Centrar horizontalmente */
  align-items: center; /* Centrar verticalmente */
}

.plan-details {
  text-align: center; /* Alinear el texto al centro */
}

.descripcion {
  text-align: center; /* Alinear la descripción al centro */
}

.rowit {
  display: flex; /* Utilizar flexbox para alinear los cards */
  flex-wrap: wrap; /* Permitir que los cards se envuelvan en filas */
  justify-content: center; /* Centrar los cards */
}

@media (max-width: 768px) {
  .card {
    width: 100%; /* Hacer los cards más anchos en pantallas pequeñas */
    height: auto; /* Permitir que la altura se ajuste automáticamente */
  }
}


/* Para pantallas aún más pequeñas (móviles) */
@media (max-width: 480px) {
  .timeline-item {
    padding-left: 30px;
  }

  .timeline::before {
    left: 5px; /* Más cerca del borde en pantallas pequeñas */
  }
}/* paypal.css */
.section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 0;
}

.rowdatosss {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.carpaypal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 300px; /* Tamaño del card */
  height: 400px; /* Tamaño del card */
  margin: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background-color: #f9f9f9;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

.col-lg-3:hover, .col-md-3:hover, .col-sm-12:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.f_icon img {
  width: 100%;
  height: 150px; /* Tamaño de la imagen */
  object-fit: cover;
  border-bottom: 1px solid #ddd; /* Separador entre imagen y contenido */
}

.form-group {
  text-align: center;
  padding: 10px;
}

select.form-control {
  width: 100%;
  padding: 8px;
  margin-top: 10px;
  font-size: 14px;
}

input[type="image"] {
  margin-top: 10px;
  cursor: pointer;
}
.section-clients{
  width: 100%;
}
.section-testimonial-2{
  width: 100%;
}
.container-body {
  text-align: center;
  width: 80%;
  margin: 0 auto;
  height: 50vh;
}
.section_about{
  width: 100%;
}
/* src/components/ModalNoticias.css */
.modal-overlay-noticia {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.h3mod{
  text-align: center;
  color: #fff;
}
.modal-content-noticia {
  background: #3093a64d;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 60%;
  position: relative;
}

.modal-header-noticia {
  display: flex;
  justify-content: center; /* Centrar el contenido horizontalmente */
  align-items: center;
  text-align: center; /* Asegura que el texto esté centrado dentro de su contenedor */
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.modal-close {
  font-size: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
}

.modal-body-noticia {
  margin-top: 20px;
}

.noticia-item {
  margin-bottom: 20px;
}

.noticia-titulo {
  font-size: 1.2rem;
  margin-bottom: 5px;
}

.noticia-fecha {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 10px;
}

.noticia-contenido {
  font-size: 1rem;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center; /* Centrar verticalmente */
  -ms-flex-pack: center;
  justify-content: center; /* Centrar horizontalmente */
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: .3rem;
  border-bottom-left-radius: .3rem;
  flex-wrap: wrap;
}

.modal-close-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-close-btn:hover {
  background-color: #0056b3;
}
.image-container {
  position: relative;
  display: inline-block;
  width: 100%; /* Asegura que el contenedor de la imagen ocupe todo el ancho del padre */
  height: 250px; /* Fija una altura estándar para las imágenes */
  overflow: hidden; /* Oculta cualquier contenido que se salga del contenedor */
}

.imgCard {
  width: 100%; /* Asegura que la imagen se ajuste al ancho del contenedor */
  height: 100%; /* Asegura que la imagen se ajuste a la altura del contenedor */
  object-fit: cover; /* Mantiene el aspecto de la imagen y la recorta si es necesario */
  transition: opacity 0.3s ease; /* Agrega una transición suave en la opacidad */
}

.view-details-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  border: none;
  padding: 10px;
  font-size: 20px;
  border-radius: 50%;
  cursor: pointer;
  display: none;
  z-index: 10;
  transition: opacity 0.3s ease;
}

.image-container:hover .view-details-button {
  display: block;
}

.image-container:hover .imgCard {
  opacity: 0.7; /* Reduce la opacidad para dar un efecto visual al pasar el mouse */
}

.view-details-button:hover {
  background-color: rgba(0, 0, 0, 0.9);
}
.col-6 {
  grid-column: span 6;
}
.product-details-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 20px;
  margin-bottom: 40px;
}
.imgedetail{
  width: 100vh;
}
.add-to-cart-buttonshow{
  padding: 0.5rem 1rem;
  background-color: #28a745; /* Verde característico para añadir al carrito */
  color: #fff; /* Texto blanco para buen contraste */
  border: none; /* Eliminamos los bordes predeterminados */
  border-radius: 5px; /* Borde redondeado para un look moderno */
  font-size: 1rem; /* Tamaño de texto adecuado */
  font-weight: 600; /* Hacemos el texto más grueso */
  cursor: pointer; /* Indicamos que es clickeable */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Transición suave */
}
/*2024*/
/* Estilos para la sección de noticias */
.noticias-section {
  padding: 60px 0;
  background-color: #f8f9fa;
  display: flex;
  justify-content: center;  /* Centra el contenido horizontalmente */
  align-items: center;      /* Centra el contenido verticalmente */
  min-height: 100vh;        /* Asegura que la sección ocupe toda la altura de la pantalla */
}

.container_noticias {
  display: flex;
  justify-content: center;  /* Centra las columnas dentro del contenedor */
  flex-wrap: wrap;          /* Permite que las columnas se ajusten en pantallas pequeñas */
}

.row_noticiahome {
  display: flex;
  justify-content: center;  /* Centra las tarjetas dentro de la fila */
  flex-wrap: wrap;          /* Permite que las tarjetas se ajusten a varias líneas si es necesario */
}

.col-lg-4, .col-md-6 {
  display: flex;
  justify-content: center;  /* Centra cada tarjeta dentro de su columna */
  margin-bottom: 20px;
}

.card {
  width: 100%; /* Asegura que la tarjeta ocupe todo el espacio disponible */
  max-width: 350px; /* Limita el tamaño máximo de la tarjeta */
}

/* Estilos para los cards de noticias */
.noticia-card {
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin: 0 auto; /* Centra la tarjeta dentro de su columna */
}

.noticia-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.noticia-img {
  height: 200px;
  object-fit: cover;
  width: 100%;
}

/* Estilos para el cuerpo del card */
.card-body {
  padding: 20px;
  background-color: #fff;
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
}

.card-text {
  font-size: 1rem;
  color: #666;
  margin-bottom: 15px;
}

.btn-primary {
  background-color: #223a66;
  border-color: #223a66;
  color: #fff;
  padding: 10px 15px;
  font-size: 1rem;
  text-align: center;
  width: 100%;
  border-radius: 30px;
  text-transform: uppercase;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #e12454;
  border-color: #e12454;
}

.btn-rounded {
  border-radius: 30px;
}

@media (max-width: 768px) {
  .col-lg-4 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .noticia-card {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

@media (max-width: 992px) {
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.catalog-container-cardCTI {
  padding: 20px;
}

.promociones-grid-cardCTI {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Más responsivo */
  gap: 20px;
}

.card-cardCTI {
  position: relative;
  overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 400px; /* Altura uniforme para todas las tarjetas */
  width: 100%; /* Ajusta la anchura por defecto */
}

.card-img-cardCTI {
  width: 100%;
  height: 100%;
  object-fit: cover; /* La imagen ocupará todo el contenedor */
  display: block;
}

.card-content-cardCTI {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 10px;
  box-sizing: border-box;
}

.card-title-cardCTI {
  font-size: 18px;
  font-weight: bold;
}

.promo-nombre-cardCTI {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

.promo-precio-cardCTI {
  margin: 5px 0 0;
  font-size: 14px;
}

.text-center-cardCTI {
  text-align: center;
  margin-bottom: 20px;
}

.loading-cardCTI {
  font-size: 16px;
  color: #555;
  text-align: center;
}

.error-cardCTI {
  font-size: 16px;
  color: red;
  text-align: center;
}

/* Si hay un único card */
.promociones-grid-cardCTI > .card-cardCTI:first-child:last-child {
  width: 50%; /* Ocupa 50% del ancho de la pantalla */
  margin: 0 auto; /* Centra la tarjeta */
}

/* Media Queries para Responsividad */
@media (max-width: 1200px) {
  .promociones-grid-cardCTI {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  .card-cardCTI {
    height: 350px;
  }

  .promociones-grid-cardCTI > .card-cardCTI:first-child:last-child {
    width: 70%; /* Ajuste en pantallas medianas */
  }
}

@media (max-width: 768px) {
  .promociones-grid-cardCTI {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .card-cardCTI {
    height: 300px;
  }

  .promociones-grid-cardCTI > .card-cardCTI:first-child:last-child {
    width: 90%; /* Pantallas pequeñas ocupan casi todo el ancho */
  }

  .card-title-cardCTI {
    font-size: 16px;
  }

  .promo-nombre-cardCTI,
  .promo-precio-cardCTI {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .promociones-grid-cardCTI {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .card-cardCTI {
    height: 250px;
  }

  .promociones-grid-cardCTI > .card-cardCTI:first-child:last-child {
    width: 100%; /* Ocupa todo el ancho en pantallas muy pequeñas */
  }

  .card-content-cardCTI {
    padding: 8px;
  }

  .card-title-cardCTI {
    font-size: 14px;
  }

  .promo-nombre-cardCTI,
  .promo-precio-cardCTI {
    font-size: 12px;
  }
}
/* Contenedor para la oferta */
.offer-container {
  position: absolute;
  top: 0; /* Posición en la parte superior */
  right: 0; /* Alineado a la derecha */
  z-index: 10; /* Asegura que esté encima del contenido de la tarjeta */
}

/* Imagen de oferta especial */
.offer-image-special {
  width: 120px; /* Ajustar tamaño según sea necesario */
  height: auto;
  object-fit: cover; /* Mantiene proporciones */
}
.btn-primary-card-ofer{
    background-color: #223a66;
    border-color: #223a66;
    color: #fff;
    padding: 10px 15px;
    font-size: 1srem;
    text-align: center;
    width: 100%;
    border-radius: 30px;
    text-transform: uppercase;
    font-weight: bold;
    transition: background-color 0.3s ease;
}
